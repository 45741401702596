import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterModule } from '@angular/router';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'ea-home-page',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterLink, RouterModule, MatIcon],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  constructor(private readonly FB: FreshPaintService) {
    this.FB.sendEvent('page_view', {
      Page_type: 'home',
      Page_name: 'home',
    });
  }
}
